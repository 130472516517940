import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import { TitleContentWrapper } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RichText from '@components/RichText'
import { Heading1 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { animation } from '@styles/vars/animation.style'

const TitleContent = ({ title, content, alignment, pageContent }) => {
  const stack = alignment === 'Stack'

  return (
    <TitleContentWrapper pageContent={pageContent}>
      <Container>
        <Grid>
          <GridItem desk={3}>
            <Heading1>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading1>
            <Spacer size={24} />
          </GridItem>
          <GridItem desk={6} deskStart={stack ? 1 : 7}>
            <RichText content={content} delay={animation.blockDuration} />
            <Spacer size={24} />
          </GridItem>
        </Grid>
      </Container>
    </TitleContentWrapper>
  )
}

TitleContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
  alignment: PropTypes.string,
  pageContent: PropTypes.bool,
}

TitleContent.defaultProps = {
  pageContent: false,
}

export default TitleContent
