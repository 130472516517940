import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'

export const TitleContentWrapper = styled.div`
  ${({ pageContent }) => `
    ${clamp('padding-bottom', pageContent ? 28 : 24, pageContent ? 160 : 48)};
    ${clamp('padding-top', pageContent ? 80 : 180, 300)};
  `}
  position: relative;
`
